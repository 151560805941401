import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [0,2,3,6];

export const dictionary = {
		"/": [10],
		"/account/signup": [~11],
		"/admin/accounts": [12,[2]],
		"/admin/components": [13,[2]],
		"/admin/contracts": [14,[2]],
		"/admin/design": [15,[2]],
		"/admin/design/components": [16,[2]],
		"/admin/frames": [17,[2]],
		"/admin/percs-client-components": [18,[2]],
		"/admin/testing": [19,[2]],
		"/creator": [20,[3]],
		"/creator/account": [21,[3]],
		"/creator/audiences": [22,[3,4]],
		"/creator/audiences/contracts/[address]": [24,[3,4]],
		"/creator/audiences/[id]": [23,[3,4]],
		"/creator/automations": [~25,[3]],
		"/creator/campaigns": [26,[3]],
		"/creator/campaigns/edit/[id]": [28,[5]],
		"/creator/campaigns/edit/[id]/campaign-design": [29,[5]],
		"/creator/campaigns/edit/[id]/ticket-design": [30,[5]],
		"/creator/campaigns/new": [31,[3]],
		"/creator/campaigns/[id]": [27,[3]],
		"/creator/contacts": [32,[3,6]],
		"/creator/contacts/lists": [34,[3,6]],
		"/creator/contacts/lists/[id]": [~35,[3,6]],
		"/creator/contacts/[id=number]": [~33,[3,6]],
		"/creator/customers": [~36,[3]],
		"/creator/customers/[id]": [~37,[3]],
		"/creator/frames": [38,[3]],
		"/creator/frames/new": [40,[3]],
		"/creator/frames/[id]": [~39,[3]],
		"/creator/messaging": [41,[3]],
		"/creator/settings": [42,[3]],
		"/creator/tickets/preview": [43,[7]],
		"/landing-page-iframe": [~44,[8]],
		"/login": [~45],
		"/login/account": [~47],
		"/login/no_account": [48],
		"/login/profile": [~49],
		"/login/[token]": [~46],
		"/offers": [50],
		"/offers/[id]-[slug]": [~51],
		"/offers/[id]-[slug]/scan": [52],
		"/offers/[id]-[slug]/tickets/[value]": [53],
		"/signup": [~54],
		"/wallet-verification-challenge": [55,[9]],
		"/wallet-verification-challenge/[uuid]": [56,[9]],
		"/wallet-verification-challenge/[uuid]/status": [57,[9]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';